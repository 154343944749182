import React from 'react';
import Header from "./Header"

export class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <Header />
                    <div className="container" style={{padding:"20px", minHeight:"300px"}}>
                        <div className="row">
                            <div className="col-12">
                                <h1>Error</h1>
                                <p>A problem has occurred. Please try again, but if this error happens again please contact <a className="link" href="mailto:info@librariesparks4all.com">info@librariesparks4all.com</a></p>
                                <p><a className="link" href="/">Click here</a> to try again</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return this.props.children; 
    }
}
export default ErrorBoundary;
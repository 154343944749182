import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Layout from './components/layout/Layout';
import './fonts/BebasNeueRegular.ttf';
import './fonts/BebasNeueBold.ttf';
import './fonts/UniversLTStdCn.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Layout>
            <App />
        </Layout>
  </React.StrictMode>
);

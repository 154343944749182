import React, { useEffect, Fragment, useState } from 'react';
import { lookupAddress, lookupAddressDetails } from '../api/Lookup.js'
import SubHeading from './SubHeading'
import BodyText from './BodyText'

function PropertyTax() {
    // Different test is displayed if a parcels type is one of the below
    const exemptUseTypes = ["AGRICULTURAL PRESERVE", "TAX EXEMPTS", "AGRICULTURAL", "GOVERNMENT"];
    const multiFamilyUseTypes = ["RESID. MOBIL/MANUFACTURED HOME PARK", "RESID. RETIREMENT HOME", "RESID. CONDOMINIUM", "RESID. MULTIPLE FAMILY", "RESID. MOBILE/MANUFACTURED HOMES"];

    const [isLoading, setLoading] = useState(false);
    const [addressOptions, setAddressOptions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [apn, setAPN] = useState("");
    const [parcelDetails, setParcelDetails] = useState("");
    const [resultDisplay, setResultDisplay] = useState("none");
    const [addressListDisplay, setAddressListDisplay] = useState("none");
    const [noMatchingAddressDisplay, setNoMatchingAddressDisplay] = useState("none");
    const [closeIconDisplay, setCloseIconDisplay] = useState("none");
    const [manualParcelSizeDisplay, setManualParcelSizeDisplay] = useState("none");
    const [parcelSize, setParcelSize] = useState("");
    const [parcelSizeDisplay, setParcelSizeDisplay] = useState("");

    const [tax, setTax] = useState(0);
    const [monthlyTax, setMonthlyTax] = useState(0);

    // Search for address and display possible matches
    function addressSearch(searchText) {
        setLoading(true);
        setSearchText(searchText);
        setResultDisplay("none")
        lookupAddress(searchText).then(response => {
            setAddressOptions(response);

            if (response !== null && response.length > 0) {
                setNoMatchingAddressDisplay("none");
                setAddressListDisplay("block");
            }
            else {
                setNoMatchingAddressDisplay("block");
                setAddressListDisplay("none");
            }
            setLoading(false);
        });
    }

    // get property details and display
    function addressClicked(apn, address) {
        setAPN(apn);
        setSearchText(address);
        setLoading(true);
        setAddressListDisplay("none");
        lookupAddressDetails(apn).then(details => {
            setParcelDetails(details);
            setResultDisplay("block")
            setCloseIconDisplay("block");

            // if we have a lot size use that, else show input field for lot size
            if (details.lot_sqft !== 0 && details.lot_sqft !== '' && details.lot_sqft !== null) {
                setParcelSize(details.lot_sqft);
                setParcelSizeDisplay(details.lot_sqft.toLocaleString() + " sqft");
                setManualParcelSizeDisplay("none");
            }
            else {
                setManualParcelSizeDisplay("block");
                setParcelSizeDisplay("");
            }
            
            setLoading(false);
        });
    }

    // calculate tax everytime the parcelSize is changed
    useEffect(() => {
        calculateTax(parcelSize);
    }, [parcelSize]);

    function calculateTax(lotSize) {
        // The tax is $0.02/sqft/year, capped at 43,560 sq ft (1 acre)
        if (lotSize > 43560) {
            lotSize = 43560;
        }

        var lTax = lotSize * 0.02;
        setTax(lTax);

        setMonthlyTax(lTax / 12);
    }

    // Reset page
    function closeAddressListClicked() {
        setAddressListDisplay("none");
        setSearchText("");
        setResultDisplay("none");
        setCloseIconDisplay("none");
        setParcelSize("")
        setAPN("");
        setAddressOptions([]);
        setParcelDetails("");
        setNoMatchingAddressDisplay("none");
        setCloseIconDisplay("none");
        setManualParcelSizeDisplay("none");
        setParcelSizeDisplay("");
        setTax(0);
    }

    function showCloseIcon(searchText) {
        if (searchText === "") {
            setCloseIconDisplay("none");
        }
        else {
            setCloseIconDisplay("block");
        }
    }

    // Create buttons for possible address matches
    var addressListItems;
    if (addressOptions != null) {
        addressListItems = addressOptions.map(address => {
            return (
                <button key={address.address} onClick={() => addressClicked(address.apn, address.address)} className="btnListItem">{address.address}</button>
            );
        });
    }

    // format currency
    var currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    function UpdateSearchText(searchText) {
        setSearchText(searchText);
        if (searchText.length > 3) {
            addressSearch(searchText)
        }
        else {
            setAddressListDisplay("none");
            setResultDisplay("none");
            setParcelSize("")
            setAPN("");
            setAddressOptions([]);
            setParcelDetails("");
            setNoMatchingAddressDisplay("none");
            setManualParcelSizeDisplay("none");
            setParcelSizeDisplay("");
            setTax(0);
        }
    }

    return (
        <Fragment>
            <div>
                <div style={{ display: "inline-block" }}><SubHeading text="Find Your Tax"></SubHeading></div>  {isLoading === true ? <div className="spinner-border" role="status"></div> : null}
                <div style={{ marginBottom: "10px" }}>Start by entering your address below.</div>
                <div className="input-group">
                    <input value={searchText} onChange={e => { UpdateSearchText(e.target.value); showCloseIcon(e.target.value) }} style={{ border: "none" }} id="txtAddress" type="text" className="form-control" placeholder="Start typing your address..." aria-label="address"></input>
                    <button style={{ display: closeIconDisplay }} type="button" className="close" aria-label="Close" onClick={() => closeAddressListClicked()}>&times;</button>
                </div>
            </div>

            <div style={{ display: addressListDisplay }} id="addressList">
                {addressListItems}
            </div>

            <div style={{ display: noMatchingAddressDisplay, fontSize: "18px", fontStyle: "italic" }}>No match found - the address might not be in the City of San Diego.</div>
           
            <div id="dvResults" style={{backgroundColor: "white", padding: "35px", borderRadius: "4px", marginTop: "20px", display: resultDisplay}}>
                { parcelDetails ? 
                    <div>
                        <SubHeading className="green" text={parcelDetails.address}></SubHeading>
                        <BodyText text={"Parcel # (APN): " + apn}></BodyText>
                        <BodyText text={"Parcel Size: " + parcelSizeDisplay}></BodyText>

                        <div style={{display: manualParcelSizeDisplay, marginTop: "40px"}}>
                            <p style={{ fontSize: "18px", fontStyle: "italic" }}>Sorry, we were unable to retrieve your parcel size. You can enter your parcel size below to calculate your estimated Parcel Tax charge.</p>

                            <label style={{fontSize: "18px"}} id="lblParcelSize">Parcel Size:</label>
                            <div className="input-group">
                                <input className="inputDark" value={parcelSize} onChange={e => { setParcelSize(e.target.value) }} style={{ maxWidth: "200px" }} id="txtParcelSize" type="number" step="1" className="form-control" aria-label="parcel size"></input>
                                <span style={{ fontSize: "18px", marginLeft:"10px", marginTop: "3px" }}>sqft</span>
                            </div>
                        </div>

                        { exemptUseTypes.includes(parcelDetails.use_type) ?
                            <div>Your property will likely be exempt from this Parcel Tax.</div> :

                            <div style={{ marginTop: "40px" }}>
                                {
                                    multiFamilyUseTypes.includes(parcelDetails.use_type) ?
                                        <div>It looks as though you're living in a multi-family residential unit. If you are not exempt, the Parcel Tax charge <span style={{ fontWeight: "bold" }}>for the entire lot</span> will be:</div> :
                                    <div>If you are not exempt, your estimated Parcel Tax charge will be:</div>
                                }

                                <span style={{ fontSize: "45px" }} className="green">{currencyFormatter.format(tax)}</span><span style={{ color: "black" }}> per year</span>

                                <div>{`(${currencyFormatter.format(monthlyTax)}/month)`}</div>

                                {
                                    multiFamilyUseTypes.includes(parcelDetails.use_type) ?
                                        <div style={{marginTop: "20px"}}>The amount that you will be charged will depend on your specific deed and covenants.</div> :
                                        null
                                }
                            </div>
                        }

                    </div> :
                    <div style={{ fontSize: "18px", fontStyle: "italic" }}>Unable to get property details.</div>
                }
            </div> 
            <p style={{ marginTop: "5px" }}>The tax is $0.02/sqft/year, capped at 43,560 sq ft (1 acre)</p>
        </Fragment>
    );
}

export default PropertyTax;
import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import ErrorBoundary from "./ErrorBoundary";

class Layout extends React.Component {
    render() {
        return (
            <ErrorBoundary>
                <Header />
                <main>{this.props.children}</main>
                <Footer />
            </ErrorBoundary>
        )
    }
}
export default Layout;
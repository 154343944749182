import Logo from '../../Images/LibrariesAndParksLogo.png' // relative path to image 

function Header() {
    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <a className="link" href="/">
                            <img alt="Libraries and Parks for All" className="headerLogo" src={Logo}></img>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
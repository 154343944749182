import './App.css';
import SubHeading from './components/SubHeading'
import PropertyTax from './components/PropertyTax'

function App() {
  return (
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="section">
                      <SubHeading text="How Much Is It Going to Cost Me?"></SubHeading>
                      <p>The Libraries and Parks Investment Act of 2024 is a parcel tax, based on the size of the lot, not on its assessed value.  Most of the property taxes that we pay are based on the value of the property, and thus can increase in unpredictable ways; the amount of this proposed parcel tax will remain relatively flat (only adjusted for inflation) over its 30 year lifetime.</p>
                  </div>

                  <div className="section">
                      <SubHeading text="Am I Subject to this Parcel Tax?"></SubHeading>
                      <ul>
                          <li>Is the parcel in the City of San Diego?  If not, no, you are not subject to this tax.</li>
                          <li>Do you own the parcel?  If not, no, you are not subject to this tax.</li>
                          <li>Is the parcel zoned for agricultural use?  If it is, no, you will not be subject to this tax.</li>
                          <li>Do you make less than $72,900/year ($104,100 for a family of 4)?  If so, no, you are not subject to this tax.</li>
                      </ul>
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-12">
                  <div className="section">
                      <PropertyTax></PropertyTax>
                  </div>
                 
                  <SubHeading text="More Information"></SubHeading>
                  <p>Text of the Libraries and Parks Improvement Act can be found <a className="link" href="https://secureservercdn.net/45.40.148.239/skm.1b8.myftpupload.com/wp-content/uploads/2022/06/San-Diego-Parcel-Tax-Legal-Text-FINAL-6-10-22-PDF-FOR-WEBSITE-1.pdf" target="_blank" rel="noreferrer">here.</a></p>
                  <p>Income Limit tables for 2022 can be found <a className="link" href="https://www.sdhc.org/wp-content/uploads/2022/AMIIncomeLimits.pdf" target="_blank" rel="noreferrer">here.</a></p>
              </div>
          </div>
      </div>
  );
}

export default App;

import Logo from '../../Images/deckard_logo_reverse.png' // relative path to image 

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6" style={{marginBottom: "5px"}}>
                        Contact <a className="link" href="mailto:info@librariesparks4all.com">info@librariesparks4all.com</a> if you have questions.
                    </div>
                    <div className="col-12 col-lg-6 text-md-end">
                        <span style={{ marginRight: "10px" }}>Engineered by</span>
                        <a href="https://www.deckard.com/"><div style={{maxWidth: "300px", display:"inline-block"}}><img alt="Deckard Technologies" src={Logo} style={{maxWidth: "300px"}} /></div></a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
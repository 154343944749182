export async function lookupAddress(address) {

    try
    {
        const lResponse = await fetch(`https://str-registration-api.dev.deckard.com/ca-san_diego-city_of_san_diego/lookupAddress/${encodeURIComponent(address)}`);

        if (lResponse.status === 200) {
            var lJson = await lResponse.json();
            // Convert into array [address, [number]]
            var lAddresses = Object.keys(lJson).map((key) => [key, lJson[key]]).sort();

            var lResult = [];

            for (var i = 0; i < lAddresses.length; i++) {
                var lAddress = lAddresses[i];

                if (lAddress[1].length > 0) {
                    lResult.push({ address: lAddress[0], apn: lAddress[1][0] });
                }
                else {
                    lResult.push({ address: lAddress[0], apn: "" });
                }
            }
            if (lResult.length > 0) {
                return lResult;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    catch (error) {
        return null;
    }
}

export async function lookupAddressDetails(apn) {

    try {
        const lResponse = await fetch(`https://str-registration-api.dev.deckard.com/ca-san_diego/parcel/${encodeURIComponent(apn)}`);

        if (lResponse.status === 200) {
            return await lResponse.json();
        }
        else {
           return null;
        }
    }
    catch (error) {
        return null;
    }
}